import router from "@/router";
import TradingReportDataService from './TradingReportDataService'
import PlatformReportDataService from "@/views/reports/services/PlatformReportDataService";
import CountryReportDataService from "@/views/reports/services/CountryReportDataService";
import BrandOverviewReportDataService from "@/views/reports/services/BrandOverviewReportDataService";
import BrandByPlatformDataService from "@/views/reports/services/BrandByPlatformDataService";

class ReportDataServiceFactory {
    reports = {
        'trading-overview': new TradingReportDataService(),
        'platform-report': new PlatformReportDataService(),
        'country-report': new CountryReportDataService(),
        'brand-overview': new BrandOverviewReportDataService(),
        'brand-by-platform': new BrandByPlatformDataService(),
    }

   create(service) {
       return this.reports[service || router.currentRoute.name]
   }
}

export default new ReportDataServiceFactory()
