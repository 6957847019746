<template>
  <b-card>
    <loading :show="isLoadingTable && !isLoadingPage">
      <b-row>
        <export-csv
            v-show="exportCsv"
            :filter="filter"
            :export-option="exportOption && !articleData"
            :export-with-image-option="exportWithImageOption && !articleData"
        />
        <table-search
            v-show="searchable && !articleData"
            ref="search"
            :fields-search="fieldsSearch"
            @clear="handleClearSearch"
            @click="clickSearch"
        />
        <b-col cols="12">
          <b-table
              ref="reportTable"
              :items="tableDataProvider"
              :fields="fieldsTrans"
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              show-empty
              :empty-text="$i18n.t('table.no_data')"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              @sort-changed="sortingChanged"
          >
            <template
                v-for="(_, slotName) of $scopedSlots"
                v-slot:[slotName]="scope"
            >
              <slot
                  :name="slotName"
                  v-bind="scope"
              />
            </template>

            <template
                v-if="isTopFields"
                v-slot:thead-top=""
            >
              <b-tr>
                <b-th
                    v-for="topField of topFields"
                    :key="topField.field"
                    :colspan="topField.col"
                    class="bg-dark text-white text-center border-left border-right border-white width-150"
                >
                  {{ (topField.col > 1) ? $i18n.t(`reports.table.fields.${topField.field}`) : '' }}
                </b-th>
              </b-tr>
            </template>
          </b-table>
        </b-col>
        <table-pagination
            v-show="pagination"
            :total-rows="totalRows"
            :view-rows="viewItems.length"
            :per-page.sync="perPage"
            :current-page.sync="currentPage"
            @change-per-page="handlePerPageChange"
        />
      </b-row>
    </loading>
  </b-card>
</template>
<script>

import TablePagination from "./components/pagination.vue"
import TableSearch from "./components/search.vue"
import ExportCsv from "./components/export.vue"
import ReportDataServiceFactory from "@/views/reports/services/ReportDataServiceFactory";

export default {
  components: {
    TablePagination,
    TableSearch,
    ExportCsv
  },
  props: {
    filter: {
      type: Object,
      default: null,
    },
    isLoadingPage: {
      type: Boolean,
      default: false,
    },
    isLoadingTable: {
      type: Boolean,
      default: false,
    },
    isInitFilter: {
      type: Boolean,
      default: false,
    },
    exportCsv: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    fieldsSearch: {
      type: Array,
      default: Array,
    },
    defaultSortBy: {
      type: String,
      default: 'sku',
    },
    exportOption: {
      type: Boolean,
      default: false,
    },
    exportWithImageOption: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      perPage: 25,
      pageByRequest: 5,
      totalRows: 0,
      currentPage: 1,
      viewItems: [],
      cachedItems: [],
      fields: [],
      topFields: [],
      apiCachedItems: [],
      apiTotalRows: 1,
      articleData: null,
      reportDataService: ReportDataServiceFactory.create(),
      fetchDataTable: true,
      sortBy: this.defaultSortBy,
      sortDesc: false,
      isChangeSort: false,
    }
  },
  computed: {
    isTopFields() {
      return this.topFields.length > 0
    },
    fieldsTrans() {
      const temp = []
      Object.values(this.fields).forEach((item) => {
        temp.push({
          key: item.field,
          label: this.$i18n.t(`reports.table.fields.${item.fieldName}`),
          formatter: value => {
            return this.formatterHelper(value, item.format)
          },
          sortable: item.sortable
        })
      })
      return temp
    },
  },
  watch: {
    articleData(newVal) {
      this.$emit('change-page', newVal)
    },
    filter() {
      this.handleFilterChange()
    },
  },
  methods: {
    handleFilterChange() {
      this.currentPage = 1;
      this.clearCache();
      this.$refs.search.refresh()
      this.$refs.reportTable.refresh();
    },
    handlePerPageChange() {
      // @TODO find a better way to handle this without request the Api
      this.clearCache();
    },
    clearCache() {
      this.cachedItems = [];
      this.apiCachedItems = [];
    },
    handleClearSearch() {
      this.initTableItemsAsApi()
      this.$refs.reportTable.refresh()
    },
    clickSearch() {
      this.$refs.reportTable.refresh();
    },
    refresh(){
      this.$refs.reportTable.refresh();
    },
    initTableItemsAsApi() {
      this.currentPage = 1
      this.cachedItems = this.apiCachedItems
      this.totalRows = this.apiTotalRows
    },
    handleLocalSearch(items, ctx) {
      this.currentPage = 1;
      this.totalRows = items.length
      this.cachedItems = this.chunksData(items, ctx)
      this.viewItems = this.cachedItems[this.currentPage] || [];
      this.$refs.search.enable()
      return this.viewItems
    },
    sortingChanged(){
      this.isChangeSort = true
    },
    tableDataProvider(ctx) {
      if(!this.isInitFilter) return [];
      this.viewItems = []
      if (this.searchable && this.$refs.search.isNewSearchWord()) {
        this.$refs.search.updateLastWord()
        this.initTableItemsAsApi()
        const filterItems = this.$refs.search.searchInCachedData(this.apiCachedItems)
        if (filterItems.length > 0) {
          return this.handleLocalSearch(filterItems, ctx)
        }
      } else if (!this.isChangeSort && ctx.currentPage in this.cachedItems) {
        this.viewItems = this.cachedItems[ctx.currentPage];
        this.$refs.search.enable()
        return this.viewItems;
      }
      this.isChangeSort = false
      const params = {
        perPage: ctx.perPage,
        page: ctx.currentPage,
        pageByRequest: this.pageByRequest,
        sortBy: ctx.sortBy,
        sortDirection: ctx.sortDesc ? 'desc' : 'asc',
      };
      this.changeLoadingStatus(true)
      if (this.$route.params.sku) {
        const allParams = {...params, ...this.getFixFilterForInternalPage()};
        return this.reportDataService.get(
            this.$route.params.sku,
            allParams,
        ).then(res => {
          return this.handleResponse(res.data.data, ctx)
        })
      }
      let allParams = {...params, ...this.filter}
      if (!this.$refs.search.isEmpty()) {
        allParams = {...allParams, ...{search: this.$refs.search.getWord()}}
      }
      return this.reportDataService.getAll(allParams).then(res => {
        return this.handleResponse(res.data.data, ctx)
      })
    },
    chunksData(data, ctx) {
      const cachedItems = []
      const chunks = this.lodash.chunk(data, ctx.perPage);
      let currentPage = ctx.currentPage;
      chunks.forEach(chunk => {
        cachedItems[currentPage] = chunk;
        currentPage += 1;
      });
      return cachedItems
    },
    handleResponse(response, ctx) {
      this.articleData = response.article;
      this.fields = response.table_fields
      this.topFields = response.table_top_fields
      this.cachedItems = this.chunksData(response.data, ctx);
      this.totalRows = response.total;
      if (this.$refs.search.isEmpty()) {
        this.apiTotalRows = this.totalRows
        this.apiCachedItems = this.cachedItems
      }
      this.changeLoadingStatus(false)
      this.$refs.search.enable()
      this.viewItems = this.cachedItems[ctx.currentPage] || [];
      return this.viewItems;
    },
    changeLoadingStatus(status) {
      this.$emit('update:is-loading-table', status)
    },
    getFixFilterForInternalPage() {
      const allowedFilter = ['startDate', 'endDate']
      const fixFilter = {}
      allowedFilter.forEach((key) => {
        if (key in this.filter) {
          fixFilter[key] = this.filter[key]
        }
      })
      return fixFilter
    },
  }
}
</script>

<style>
.image{
  width: 100px;
  height: 60px;
  object-fit: contain;
}
.table thead th,.table tfoot th {
  vertical-align: middle;
}
</style>
