import ReportDataService from "./ReportDataService";

export default class PlatformReportDataService extends ReportDataService {
    apiUrl = 'reports/platform-report';

    defaultOption = {
        series: [],
        labels: [],
    }

    charts = [
        {
            isActive: true,
            name: 'shares',
            type: 'pieChart',
            option: {},
            defaultOption: this.defaultOption
        },
        {
            isActive: true,
            name: 'growth',
            type: 'barChart',
            option: {},
            defaultOption: this.defaultOption
        },
    ]

    handleChartResponse(response) {
        const data = response.data.data
        this.charts[0].option.series = data.shares
        this.charts[0].option.labels = data.labels
        this.charts[1].option.series = data.growth
        this.charts[1].option.labels = data.labels
    }

    handleConstraint(params) {
        this.charts[0].isActive = params.shares !== false
        this.charts[1].isActive = params.growth !== false
    }
}
